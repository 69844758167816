<template>
  <div class="realname-container">
    <el-tabs>
      <el-tab-pane label="重点单位消防管理人员明细">
        <div class="member">
          <div class="member-info">
            <div class="member-type">
              <span>消防安全责任人</span>
            </div>
            <div class="member-form">
              <div class="form-item">
                <div class="form-label"><span>姓名</span></div>
                <div class="form-content">
                  <div class="text">{{member.realname}}</div>
                  <div class="btn">
                    <el-button v-show="JSON.stringify(member)==='{}'&&isAdmin" style="color:#67C23A" type="text" size="default" icon="el-icon-plus" @click="open('add1',464)"></el-button>
                    <el-button v-show="JSON.stringify(member)!=='{}'&&isAdmin" style="color:#E6A23C" type="text" size="default" icon="el-icon-edit" @click="open('edit',member)"></el-button>
                    <el-button v-show="JSON.stringify(member)!=='{}'&&isAdmin" style="color:#F56C6C" type="text" size="default" icon="el-icon-delete" @click="delMember(member.id,member.job)"></el-button>
                  </div>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>性别</span></div>
                <div class="form-content">
                  <el-tag type="danger" size="normal" v-show="member.sex===1">女</el-tag>
                  <el-tag type="primary" size="normal" v-show="member.sex===0">男</el-tag>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>身份证号</span></div>
                <div class="form-content">{{member.idCard}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>是否经过消防培训</span></div>
                <div class="form-content">
                  <el-tag type="danger" size="normal" v-show="member.isTrain===1">否</el-tag>
                  <el-tag type="primary" size="normal" v-show="member.isTrain===0">是</el-tag>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>从事工作种类</span></div>
                <div class="form-content">{{member.jobName}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>证书编号</span></div>
                <div class="form-content">{{member.certificateCode}}</div>
              </div>
            </div>
            <div class="member-form">
              <div class="form-item">
                <div class="form-label"><span>所属部门</span></div>
                <div class="form-content">{{member.departmentName}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>职务</span></div>
                <div class="form-content">{{member.unitRoleName}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>联系电话</span></div>
                <div class="form-content">{{member.phoneNumber}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>任命书</span></div>
                <div class="form-content"></div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>变更时间</span></div>
                <div class="form-content">{{member.changeTime|dateFormat}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>证书有效期</span></div>
                <div class="form-content">{{member.validTime|dateFormat}}</div>
              </div>
            </div>
          </div>
          <div class="member-info">
            <div class="member-type">
              <span>消防安全管理人</span>
            </div>
            <div class="member-form">
              <div class="form-item">
                <div class="form-label"><span>姓名</span></div>
                <div class="form-content">
                  <div class="text">{{member2.realname}}</div>
                  <div class="btn">
                    <el-button v-show="JSON.stringify(member2)==='{}'&&isAdmin" style="color:#67C23A" type="text" size="default" icon="el-icon-plus" @click="open('add1',465)"></el-button>
                    <el-button v-show="JSON.stringify(member2)!=='{}'&&isAdmin" style="color:#E6A23C" type="text" size="default" icon="el-icon-edit" @click="open('edit',member2)"></el-button>
                    <el-button v-show="JSON.stringify(member2)!=='{}'&&isAdmin" style="color:#F56C6C" type="text" size="default" icon="el-icon-delete" @click="delMember(member2.id,member2.job)"></el-button>
                  </div>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>性别</span></div>
                <div class="form-content">
                  <el-tag type="danger" size="normal" v-show="member2.sex===1">女</el-tag>
                  <el-tag type="primary" size="normal" v-show="member2.sex===0">男</el-tag>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>身份证号</span></div>
                <div class="form-content">{{member2.idCard}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>是否经过消防培训</span></div>
                <div class="form-content">
                  <el-tag type="danger" size="normal" v-show="member2.isTrain===1">否</el-tag>
                  <el-tag type="primary" size="normal" v-show="member2.isTrain===0">是</el-tag>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>从事工作种类</span></div>
                <div class="form-content">{{member2.jobName}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>证书编号</span></div>
                <div class="form-content">{{member2.certificateCode}}</div>
              </div>
            </div>
            <div class="member-form">
              <div class="form-item">
                <div class="form-label"><span>所属部门</span></div>
                <div class="form-content">{{member2.departmentName}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>职务</span></div>
                <div class="form-content">{{member2.unitRoleName}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>联系电话</span></div>
                <div class="form-content">{{member2.phoneNumber}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>任命书</span></div>
                <div class="form-content"></div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>变更时间</span></div>
                <div class="form-content">{{member2.changeTime|dateFormat}}</div>
              </div>
              <div class="form-item">
                <div class="form-label"><span>证书有效期</span></div>
                <div class="form-content">{{member2.validTime|dateFormat}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="人员工种">
        <div class="member-table">
          <div class="header">
            <el-select v-model="jobId" placeholder="请选择工种" clearable size="small" @change="changeTable">
              <el-option v-for="item in jobList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button type="success" size="small" @click="open('add2')" icon="el-icon-plus" v-if="isAdmin">添加</el-button>
          </div>
          <div class="main">
            <el-table :data="memberList" stripe style="width: 100%" height="100%">
              <template v-for="(item,i) in col">
                <el-table-column :key="i" :prop="item.prop" :label="item.label" show-overflow-tooltip v-if="item.prop==='sex'">
                  <template slot-scope="scope" v-if="item.prop==='sex'">
                    <el-tag type="danger" size="normal" v-show="scope.row.sex===1">女</el-tag>
                    <el-tag type="primary" size="normal" v-show="scope.row.sex===0">男</el-tag>
                  </template>
                </el-table-column>
                <el-table-column :key="i" :prop="item.prop" :label="item.label" show-overflow-tooltip v-else>
                </el-table-column>
              </template>
              <el-table-column label="操作" width="140" v-if="isAdmin">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top">
                    <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="删除" placement="top">
                    <el-button type="danger" @click="delMember(scope.row.id,0)" size="mini" icon="el-icon-delete-solid" circle></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 表单 -->
    <el-dialog title="修改人员" :visible.sync="memberShow" width="40%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="memberForm" ref="memberForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="所属部门" :label-width="formLabelWidth">
              <span>{{memberForm.departmentName}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="用户账号" prop="username" :label-width="formLabelWidth">
              <el-input v-model="memberForm.username" placeholder="请输入用户账号" size="small" :maxlength="10" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="所属岗位" prop="preventFire" :label-width="formLabelWidth">
              <el-select v-model="memberForm.unitRoleId" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in roleList" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="所属工种" prop="job" :label-width="formLabelWidth">
              <el-select v-model="memberForm.job" placeholder="请选择工种" size="small" clearable disabled>
                <el-option v-for="(item,i) in jobTypeList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="员工名称" prop="realname" :label-width="formLabelWidth">
              <el-input v-model="memberForm.realname" placeholder="请输入员工名称" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="性别" prop="sex" :label-width="formLabelWidth">
              <el-select v-model="memberForm.sex" placeholder="请选择工种" size="small" clearable>
                <el-option label="男" :value="0"></el-option>
                <el-option label="女" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="身份证号" prop="idCard" :label-width="formLabelWidth">
              <el-input v-model="memberForm.idCard" placeholder="请输入身份证号" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="联系电话" prop="phoneNumber" :label-width="formLabelWidth">
              <el-input v-model="memberForm.phoneNumber" placeholder="请输入联系电话" size="small" :maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span>
        <el-button @click="memberShow = false">取 消</el-button>
        <el-button type="primary" @click="editMember">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 消防安全责任人/管理人添加 -->
    <el-dialog title="添加人员" :visible.sync="addShow" width="40%" v-dialogDrag :close-on-click-modal="false" @open="initTable2" @clear="userListIds=[]">
      <div class="query">
        <el-cascader :options="departmentList" v-model="departmentIds" clearable :show-all-levels="false" @change="chooseDepartment" :props="{value:'id'}" size="small">
        </el-cascader>
        <el-select v-model="unitRoleId" placeholder="请选择岗位" size="small" clearable @change="chooseRole">
          <el-option v-for="(item,i) in roleList" :key="i" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-table :data="memberList2" border stripe highlight-current-row @current-change="handleCurrentChange">
        <el-table-column label="姓名" prop="realname">
        </el-table-column>
        <el-table-column label="部门" prop="departmentName">
        </el-table-column>
        <el-table-column label="职位" prop="unitRoleName">
        </el-table-column>
        <el-table-column label="电话" prop="phoneNumber">
        </el-table-column>
      </el-table>
      <span>
        <el-button @click="addShow = false">取 消</el-button>
        <el-button type="primary" @click="addFireControlMember">添 加</el-button>
      </span>
    </el-dialog>
    <!-- 其他工种添加 -->
    <el-dialog title="添加人员" :visible.sync="addShow2" width="40%" v-dialogDrag :close-on-click-modal="false" @open="initTable2">
      <div class="query">
        <el-cascader :options="departmentList" v-model="departmentIds" clearable :show-all-levels="false" @change="chooseDepartment" :props="{value:'id',checkStrictly:true}" size="small">
        </el-cascader>
        <el-select v-model="unitRoleId" placeholder="请选择类型" size="small" clearable @change="chooseRole">
          <el-option v-for="(item,i) in roleList" :key="i" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-table :data="memberList2" border stripe @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="姓名" prop="realname">
        </el-table-column>
        <el-table-column label="部门" prop="departmentName">
        </el-table-column>
        <el-table-column label="职位" prop="unitRoleName">
        </el-table-column>
        <el-table-column label="电话" prop="phoneNumber">
        </el-table-column>
      </el-table>
      <span>
        <el-button @click="addShow2 = false">取 消</el-button>
        <el-button type="primary" @click="addFireControlMember">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      formLabelWidth: '100px',
      member: {},
      member2: {},
      memberList: [], // 人员工种---人员列表
      memberList2: [], // 未成为消防安全管理人/负责人---人员列表
      jobTypeList: [],
      roleList: [],
      departmentList: [],
      userListIds: [],
      jobId: 347,
      editJobId: '', // 要修改的人员的工种ID
      departmentIds: [],
      departmentId: '',
      unitRoleId: '',
      col: [
        {
          label: '所属部门',
          prop: 'departmentName'
        },
        {
          label: '姓名',
          prop: 'realname'
        },
        {
          label: '性别',
          prop: 'sex'
        },
        {
          label: '身份证号',
          prop: 'idCard'
        },
        {
          label: '联系电话',
          prop: 'phoneNumber'
        }
      ],
      jobList: [
        {
          label: '重点岗位人员',
          value: 347
        },
        {
          label: '消防控制室操作人员',
          value: 348
        },
        {
          label: '消防安全重点工种人员',
          value: 459
        },
        {
          label: '自动系统操作人员',
          value: 460
        },
        {
          label: '保安部巡查人员',
          value: 461
        },
        {
          label: '专兼职消防管理人员',
          value: 462
        },
        {
          label: '其他人员',
          value: 463
        }
      ],
      memberForm: {
        idCard: '',
        job: '',
        phoneNumber: '',
        realname: '',
        sex: '',
        unitRoleId: '',
        username: ''
      },
      memberFormCopy: {},
      memberShow: false,
      addShow: false,
      addShow2: false,
      rules: {
        username: [
          { required: true, message: '请输入用户账号', trigger: 'blur' }
        ],
        unitRoleId: [
          { required: true, message: '请选择所属岗位', trigger: 'change' }
        ],
        realname: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号' }
        ],
        phoneNumber: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '请输入正确的联系电话' }
        ]
      }
    }
  },
  created () {
    this.initForm1()
    this.initForm2()
    this.initTable()
    this.getDepartment()
    this.getJobType()
    this.memberFormCopy = JSON.stringify(this.memberForm)
  },
  methods: {
    async initForm1 () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.job = 464
      const { data: result } = await this.$axios.get('/user/list', { params: data })
      if (result.code === 200) {
        if (result.data.length > 0) {
          this.member = result.data[0]
        }
      }
    },
    async initForm2 () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.job = 465
      const { data: result } = await this.$axios.get('/user/list', { params: data })
      if (result.code === 200) {
        if (result.data.length > 0) {
          this.member2 = result.data[0]
        }
      }
    },
    // 人员工种---人员列表
    async initTable () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.job = this.jobId
      const { data: result } = await this.$axios.get('/user/list', { params: data })
      if (result.code === 200) {
        this.memberList = result.data
      }
    },
    // 无工种人员---人员列表
    async initTable2 () {
      const data = {}
      if (this.departmentId) {
        data.departmentId = this.departmentId
      }
      if (this.unitRoleId) {
        data.unitRoleId = this.unitRoleId
      }
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      const { data: result } = await this.$axios.get('/unitUser/list', { params: data })
      if (result.code === 200) {
        this.memberList2 = result.data
      }
    },
    // 添加工种人员
    async addFireControlMember () {
      const { data: result } = await this.$axios.post('/unitUser/addUserJob', { job: this.editJobId, userListIds: this.userListIds })
      if (result.code === 200) {
        this.addShow = false
        this.addShow2 = false
        this.$message.success('添加成功')
        if (this.editJobId === 464) {
          this.initForm1()
        } else if (this.editJobId === 465) {
          this.initForm2()
        } else {
          this.initTable()
        }
        this.userListIds = []
      } else {
        this.$message.error(result.msg)
      }
    },
    // 修改人员
    editMember () {
      console.log(this.editJobId)
      this.$refs.memberForm.validate(async valid => {
        if (valid) {
          this.member.departmentId = this.departmentId
          const { data: result } = await this.$axios.put(`/unitUser/edit/${this.memberForm.id}`, this.memberForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.memberShow = false
            if (this.editJobId === 464) {
              this.initForm1()
            } else if (this.editJobId === 465) {
              this.initForm2()
            } else {
              this.initTable()
            }
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async delMember (id, job) {
      const confirm = await this.$confirm('此操作将删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/unitUser/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          console.log(job)
          if (job === 464) {
            this.initForm1()
          } else if (job === 465) {
            this.initForm2()
          } else {
            this.initTable()
          }
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    changeTable (val) {
      this.jobId = val
      this.initTable()
    },
    open (flag, row) {
      switch (flag) {
        case 'add1':
          console.log(row)
          this.editJobId = row
          this.addShow = true
          break
        case 'edit':
          this.memberShow = true
          this.departmentId = row.departmentId
          this.getRole()
          this.$nextTick(() => {
            this.$refs.memberForm.clearValidate()
          })
          this.memberForm = JSON.parse(JSON.stringify(row))
          this.editJobId = row.job
          break
        case 'add2':
          this.addShow2 = true
          this.editJobId = this.jobId
          break
      }
    },
    // 人员列表单选
    handleCurrentChange (row) {
      this.userListIds[0] = row.id
    },
    // 人员列表多选
    handleSelectionChange (val) {
      console.log(val)
      this.userListIds = []
      val.forEach((item, i) => {
        this.userListIds.push(item.id)
      })
    },
    // 查找角色
    async getRole () {
      const { data: result } = await this.$axios.get('/unitRole/list', { params: { departmentId: this.departmentId } })
      if (result.code === 200) {
        this.roleList = result.data
      }
    },
    // 查找工种
    async getJobType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'jobType' } })
      if (result.code === 200) {
        this.jobTypeList = result.data
      }
    },
    async getDepartment () {
      const { data: result } = await this.$axios.post('/department/treeDepartment')
      if (result.code === 200) {
        this.departmentList = result.data
      }
    },
    // 选择部门查询
    chooseDepartment (val) {
      this.departmentId = val[val.length - 1]
      this.initTable2()
    },
    chooseRole (val) {
      this.initTable2()
    },
    clear () {
      this.memberForm = JSON.parse(this.memberFormCopy)
      // this.$refs.deviceForm.resetFileds()
      this.memberForm = {
        idCard: '',
        job: '',
        phoneNumber: '',
        realname: '',
        sex: '',
        unitRoleId: '',
        username: ''
      }
      this.$refs.memberForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.realname-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .el-tabs {
    width: 100%;
    height: 100%;
    ::v-deep .el-tabs__header {
      margin: 0;
      border: none;
      .el-tabs__item {
        color: #fff;
      }
      .el-tabs__item:hover {
        color: #409eff;
        cursor: pointer;
      }
      .el-tabs__item.is-active {
        color: #409eff;
      }
    }
    .el-tab-pane {
      height: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      overflow: hidden;
      .member {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-bottom: none;
        color: #fff;
        .member-info {
          flex: 1;
          display: flex;
          .member-type {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(30, 74, 115, 0.5);
            border-bottom: 1px solid #ccc;
            box-sizing: border-box;
          }
          .member-form {
            flex: 3;
            display: flex;
            flex-direction: column;
            .form-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              .form-label {
                flex: 4;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(30, 74, 115, 0.5);
                border-right: 1px solid #ccc;
                border-left: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                box-sizing: border-box;
              }
              .form-content {
                flex: 6;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #ccc;
                box-sizing: border-box;
                padding: 0 10px;
                color: #fff;
              }
            }
          }
        }
      }
      .member-table {
        width: 100%;
        height: 100%;
        background-color: transparent;
        .header {
          height: 32px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          ::v-deep .el-input__inner {
            background-color: transparent;
            color: #fff;
          }
        }
        .main {
          height: calc(100% - 40px);
          ::v-deep .el-table {
            background-color: transparent;
            tr,
            th {
              background-color: transparent;
              color: #fff;
              .el-table__expand-icon {
                color: #fff;
              }
            }
            .el-table--enable-row-hover,
            .hover-row > td,
            tr:hover > td {
              background-color: rgba(7, 104, 159, 0.15) !important;
            }
            .el-table__row--striped > td {
              background-color: rgba(7, 104, 159, 0.15) !important;
            }
          }
        }
      }
      .content {
        flex: 10;
        box-sizing: border-box;
      }
    }
  }
  .query {
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-around;
  }
}
</style>
